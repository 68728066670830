import React from 'react'
import { SEO } from '../components/UI'
import {
  InternalHeader,
  InternalContent,
  InternalWorkshopNoTab,
  InternalInstructor,
  InternalTestimonial
} from '../components/Internal'
import { WHATSAPPLINK } from '../constants/routes'
import IconFlower from '../images/common/iconFlower.svg'
import styles from './leitura-do-campo-energetico-emocional.module.css'
export default function Page() {
  return (
    <>
      <SEO title='Florais de Bach e Óleos Essenciais' />
      <InternalHeader withType>
        <small>Online</small>
        <h1>
          Florais de Bach e
          <br />
          <strong>Óleos Essenciais</strong>
        </h1>
      </InternalHeader>
      <section className={styles.container}>
        <div className={styles.content}>
          <InternalContent>
            <h2>
              Sobre o <strong>Curso</strong>
            </h2>
            <p>
              Nossa proposta é trabalhar com a sinergia entre Florais de Bach e Óleos Essenciais com o enfoque no
              desenvolvimento de cremes, escalda pés e sprays vibracionais. Faremos uso da linguagem dos sintomas
              corporais como fonte de informação para o cuidado somático, ampliaremos o espectro das essências florais,
              mostrando que seu poder de cura está além dos aspectos psicoemocionais.
            </p>
            <h2>Objetivo</h2>
            <p>
              Nosso objetivo é aprofundar o uso das essências florais em comunhão com as propriedades psicológicas e
              energéticas dos óleos essenciais para o desenvolvimento de produtos aromaterapêuticos para aplicação local
              no corpo.
            </p>
            <h2>
              O que você <strong>vai aprender</strong>
            </h2>
            <dl className='learnItems'>
              <dt>
                <IconFlower role='presentation' className='iconFlower' />
                Introdução teoria de aplicação local dos Florais de Bach
              </dt>

              <dt>
                <IconFlower role='presentation' className='iconFlower' />
                Introdução a Aromaterapia
              </dt>

              <dt>
                <IconFlower role='presentation' className='iconFlower' />O que são os óleos essenciais
              </dt>

              <dt>
                <IconFlower role='presentation' className='iconFlower' />
                Vias de ação dos óleos essenciais
              </dt>

              <dt>
                <IconFlower role='presentation' className='iconFlower' />
                Forma de uso dos óleos essenciais
              </dt>
              <dd />

              <dt>
                <IconFlower role='presentation' className='iconFlower' />
                Regras para sinergia
              </dt>
              <dd>tabelas de percentagem e diluição</dd>

              <dt>
                <IconFlower role='presentation' className='iconFlower' />
                Óleos carreadores
              </dt>

              <dt>
                <IconFlower role='presentation' className='iconFlower' />
                Propriedades Terapêuticas dos óleos essências
              </dt>

              <dt>
                <IconFlower role='presentation' className='iconFlower' />
                Produtos Aromaterapêuticos
              </dt>
              <dd>a sinergia entre óleos essenciais e Florais de Bach em sprays, escalda pés e cremes</dd>

              <dt>
                <IconFlower role='presentation' className='iconFlower' />
                Confecção de cremes, escalda pés e sprays
              </dt>
              <dd />
            </dl>
          </InternalContent>
          <div className='aside'>
            <InternalWorkshopNoTab>
              <h3>
                <small>Curso à Distância</small>
                Florais de Bach e Óleos Essenciais
              </h3>
              <ul>
                <li>Aulas online agendadas ou gravadas</li>
                <li>Material complementar incluso</li>
                <li>Certificado de conclusão</li>
                <li>Sem pré-requisitos</li>
                <li>Curso destinado a todos os públicos </li>
                <li>Acesso por 12 meses a plataforma de ensino</li>
              </ul>
              <a
                href={WHATSAPPLINK}
                target='_blank'
                rel='noopener noreferrer'
                className={`button button--purpleReverse button internalWorkshop-button`}
              >
                Inscrição Curso Online <small></small>
              </a>
            </InternalWorkshopNoTab>
            <InternalInstructor />
            <InternalTestimonial />
          </div>
        </div>
      </section>
    </>
  )
}
